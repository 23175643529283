<template>
  <div id="admin-dashboard">
    <!-- App Bar -->
    <v-app-bar
      app
      color="white"
      light
      flat
    >
      <v-app-bar-nav-icon
        v-if="!sidebarMenu"
        @click.stop="sidebarMenu = !sidebarMenu"
      />
    </v-app-bar>
    <!-- Sidebar -->
    <v-navigation-drawer 
      v-model="sidebarMenu" 
      app
      floating
      :permanent="sidebarMenu"
      :mini-variant.sync="mini"
    >
      <template #prepend>
        <v-list-item two-line>
          <v-list-item-action>
            <v-icon
              v-if="sidebarMenu"
              @click.stop="sidebarMenu = !sidebarMenu"
            >
              mdi-chevron-left
            </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <router-link
              to="/"
              class="black--text text-decoration-none"
            >
              <img
                width="50%"
                height="70%"
                src="@/assets/images/logo/with-black.png"
                class="mt-3 ml-3"
              >
            </router-link>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
          active-class="yellow"
          class="opacity-1"
        >
          <v-list-item-icon>
            <v-icon color="light-grey">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="light-grey">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      
      <template #append>
        <v-list>
          <v-list-item
            active-class="yellow"
            class="opacity-1"
            @click="logout()"
          >
            <v-list-item-icon>
              <v-icon color="light-grey">
                mdi-logout
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="light-grey">
                {{ $t("logout") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <!-- Main Container -->
    <v-container
      class="fill-height dashboard-container pa-md-10 pa-5"
      fluid
    >
      <v-row class="fill-height">
        <v-col
          cols="8"
          sm="12"
          md="8"
          lg="8"
          xl="8"
        >
          <v-row>
            <v-col
              md="4"
              sm="6"
            >
              <v-card
                class="pa-2 rounded"
                color="white"
                tile
                elevation="0"
              >
                <v-card-text>
                  <div class="mb-2 text-subtitle-2 text--opacity-75">
                    {{ $t('total_transactions') }}
                  </div>
                  <v-skeleton-loader
                    v-if="loading"
                    type="heading"
                  />
                  <template v-else>
                    <p 
                      class="text-h3 black--text mb-2"
                    >
                      ${{ totalTransaction }}
                    </p>
                    <p 
                      class="text-body-2 text--opacity-50 mb-0"
                    >
                      {{ $t('last_month') }}: ${{ transactionLastmonth }}
                    </p>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="4"
              sm="6"
            >
              <v-card
                class="pa-2 rounded"
                color="white"
                tile
                elevation="0"
              >
                <v-card-text>
                  <div class="mb-2 text-subtitle-2 text--opacity-75">
                    {{ $t('total_service_fee') }}
                  </div>
                  <v-skeleton-loader
                    v-if="loading"
                    type="heading"
                  />
                  <template v-else>
                    <p 
                      class="text-h3 black--text mb-2"
                    >
                      ${{ totalServiceFee }}
                    </p>
                    <p 
                      class="text-body-2 text--opacity-50 mb-0"
                    >
                      {{ $t('last_month') }}: ${{ serviceFeeLastmonth }}
                    </p>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="fill-height">
        <v-col
          cols="8"
          sm="12"
          md="8"
          lg="8"
          xl="8"
        >
          <v-row>
            <v-col
              md="4"
              sm="6"
            >
              <v-card
                class="pa-2 rounded"
                color="white"
                tile
                elevation="0"
              >
                <v-card-text>
                  <div class="mb-2 text-subtitle-2 text--opacity-75">
                    {{ $t('users_registered') }}
                  </div>
                  <v-skeleton-loader
                    v-if="loading"
                    type="heading"
                  />
                  <p 
                    v-else
                    class="text-h3 black--text mb-0"
                  >
                    {{ totalUsers }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              md="4"
              sm="6"
            >
              <v-card
                class="pa-2 rounded"
                color="white"
                tile
                elevation="0"
              >
                <v-card-text>
                  <div class="mb-2 text-subtitle-2 text--opacity-75">
                    {{ $t('hosts_registered') }}
                  </div>
                  <v-skeleton-loader
                    v-if="loading"
                    type="heading"
                  />
                  <p 
                    v-else
                    class="text-h3 black--text mb-0"
                  >
                    {{ totalHosts }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

// graphql
import { GET_DASHBOARD_INFO } from "@/graphql/admin";

export default {
  data() {
    return {
      sidebarMenu: true,
      toggleMini: false,
      loading: false,
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard", url: "/admin/dashboard" },
        { title: "Sessions", icon: "mdi-clock", url: "/admin/sessions" },
        { title: "Hosts", icon: "mdi-calendar-blank", url: "/admin/hosts" },
        { title: "Users", icon: "mdi-help-circle-outline", url: "/admin/users" },
      ],

      totalTransaction: 0,
      transactionLastmonth: 0,
      totalServiceFee: 0,
      serviceFeeLastmonth: 0,
      totalUsers: 0,
      totalHosts: 0,
    }
  },
  computed: {
    mini() {
      return (this.$vuetify.breakpoint.smAndDown) || this.toggleMini
    },
    ...mapGetters([ 'user', 'jwt' ]),
  },
  async mounted() {
    await this.getDashboardInfo()
  },
  methods:{
    /**
     * Logout
     */
    async logout() {
      await this.$store.dispatch("logout");
      window.location = "/";
    },

    /**
     * Get Dashboard Info
     */
    async getDashboardInfo(){
      this.loading = true
      const { data: { getDashboardInfo } } = await this.$apollo.query({
        query: GET_DASHBOARD_INFO,
        variables: {
          tokenInput: { 
            email: this.user.email,
            accesstoken: this.jwt,
          },
        },
      });
      if (getDashboardInfo.success) {
        this.totalTransaction     = getDashboardInfo.totalTransaction
        this.transactionLastmonth = getDashboardInfo.transactionLastmonth
        this.totalServiceFee      = getDashboardInfo.totalServiceFee
        this.serviceFeeLastmonth  = getDashboardInfo.serviceFeeLastmonth
        this.totalUsers           = getDashboardInfo.totalUsers
        this.totalHosts           = getDashboardInfo.totalHosts
      } 
      this.loading = false
    },
  },
}
</script>
<style lang="scss" scoped>
  #admin-dashboard {
    min-height: calc(100vh - 75px);
    background-color: #F1F3F6;
  }
  .dashboard-container {
    margin-top: 9px;
  }
</style>
